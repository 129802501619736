import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import BlogPostPreviewList from '../../components/blog-post-preview-list'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop, RelatedPagesPanel, RelatedPagesGroup, RelatedPagesLink, PanelLabel, ContainerTwoCol, ContainerContent } from '../../containers'
import Hero from '../../components/hero'
import PortableText from '../../components/portableText'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from 'react-tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { MdHelpOutline, MdHelp } from "react-icons/md";

import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer, Legend
} from 'recharts';
import { DiscussionEmbed } from 'disqus-react'
import { AdPanel, SidebarPanel, SidebarGrid } from '../index'
import EmailOptin from '../../components/EmailOptin'
import Sidebar from '../../components/Sidebar'
import { GraphDesktop, GraphMobile, AxisLabel } from '../../components/GraphComponents'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query CoastFireJobsDatabaseQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      metaImage {
        ...SanityImage
        alt
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
          fixed(width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          authors{
            author{
              name
              id
            }
          }
          mainImage {
            asset{
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 400) {
                ...GatsbySanityImageFixed
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const H2 = styled.h2`
  font-weight: 300;
`

const CalculatorGrid = styled(ContainerMain)`
  background-color: ${props => props.theme.theme.bg.primary};
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 3fr;
  grid-gap: 48px;
  align-items: start;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`

// const GraphDesktop = styled.div`
//   display: block;

//   @media (max-width: 600px) {
//     display: none;
//   }
// `

// const GraphMobile = styled.div`
//   display: none;

//   @media (max-width: 600px) {
//     display: block;
//   }

//   .recharts-text, .recharts-cartesian-axis-tick-value {
//     fill: ${props => props.theme.theme.text.primary} !important;
//   }
// `

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 24px 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiSlider-markLabel, .MuiSlider-markLabelActive {
    color: ${props => props.theme.theme.text.primary};
  }
`

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`


const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const Countdown = styled.div`
  border: 1px solid ${props => props.theme.theme.colors.green};
  background-color: ${props => props.theme.theme.colors.transparentGreen};
  border-radius: 4px;
  padding: 16px;
  font-size: 1rem;
`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

const AirTableGrid = styled.iframe`
  width: 100%;
  height: 1000px;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
`

const AirTableForm = styled.iframe`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  height: 1600px;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
`

const CustomTooltip = (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    console.log(payload)
    console.log(label)

    return(
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Age: </strong>
          {payload[0].payload.age}</CardText400>
        <CardText400>
          <strong>Net Worth: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.actualNetWorth.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
        <CardText400>
          <strong>Coast FIRE number: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.theoreticalNetWorth.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
        <CardText400>
          <strong>FIRE number: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.fireNumber.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    )
  }
  return(null)

}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CoastFireJobsDatabase = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const posts = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO
        title={'Coast FIRE Jobs Database - Jobs for Coasting to FI'}
        description={'A crowdsourced collection of jobs that allow you to "coast" to Financial Independence'}
        keywords={site.keywords}
      />
      <ReactTooltip />

      <ContainerMain>
        <ContainerContent>
          <h1>Coast FIRE Jobs Database</h1>
          <H2>A crowdsourced collection of jobs that allow you to "coast" to Financial Independence</H2>
          <p>
            <a href="https://airtable.com/shr4PtzdAFXSHaRWj/tblcXVb3q1TdIlSRw?backgroundColor=green&viewControls=on" target="_blank">View AirTable Database full screen in new tab</a>
          </p>
        </ContainerContent>
      </ContainerMain>

      <ContainerMain>
        <AirTableGrid src="https://airtable.com/embed/shr4PtzdAFXSHaRWj?backgroundColor=green&viewControls=on" scrolling="yes"></AirTableGrid>
        {/* <iframe src="https://airtable.com/embed/shr4PtzdAFXSHaRWj?backgroundColor=green&viewControls=on" frameborder="0" onmousewheel="" width="100%" height="533" style="background: transparent; border: 1px solid #ccc;"></iframe> */}

        <AirTableForm src="https://airtable.com/embed/shr0ENGaOxmm5Jr7v?backgroundColor=green"></AirTableForm>
        {/* <iframe class="airtable-embed" src="https://airtable.com/embed/shr0ENGaOxmm5Jr7v?backgroundColor=green" frameborder="0" onmousewheel="" width="100%" height="533" style="background: transparent; border: 1px solid #ccc;"></iframe> */}

        <ContainerContent>
          <p>
            Coast FIRE is when you have enough in your retirement accounts that without any additional contributions,
            your net worth will grow to support retirement at a traditional retirement age.
          </p>
          <p>
            Once your net worth has passed the Coast FIRE milestone, you still need to earn enough to cover your monthly cost of living, but you no longer need to save money for retirement.
            If you save up and invest enough money early enough in life, you can allow your existing investments to compound over time and grow to be enough to retire on at a traditional retirement age.
            This strategy of "Coasting to FIRE" gives you the freedom to pursue a different job that pays less, shift to working part-time, or just have more spending money to enjoy life.
          </p>
          <p>
            To learn more about the details and math behind Coast FIRE, checkout my
            popular <Link to='/tools/coast-fire-calc'>Coast FIRE Calculator</Link>.
          </p>
        </ContainerContent>

        <RelatedPagesPanel style={{marginTop: '24px'}}>
          <PanelLabel>Related Pages</PanelLabel>
          <RelatedPagesGroup>
            <Link to='/tools/coast-fire-calc'>Coast FIRE Calculator</Link>
            <Link to='/coast-fire'>Coast FIRE Complete Guide</Link>
            <Link to='/coast-fire-jobs'>Best Jobs for Coast FIRE</Link>
            <Link to='/coast-fire-grid'>Coast FIRE Grid</Link>
            <Link to='/tools/barista-fire-calc'>Barista FIRE Calculator</Link>
          </RelatedPagesGroup>
        </RelatedPagesPanel>

      </ContainerMain>


      <Backdrop>
        <ContainerMain>
          <DiscussionEmbed
              shortname={process.env.GATSBY_DISQUS_NAME}
              config={{
                  identifier: 'coast-fire-jobs-database'
              }}
          />
        </ContainerMain>
      </Backdrop>
    </Layout>
  )
}

export default CoastFireJobsDatabase
